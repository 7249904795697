<template>
  <v-row>
    <v-col cols="12">
      <access-api />
      <v-card class="mt-5" flat>
        <v-toolbar class="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">Webhooks</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0 pb-4">
          <PermissionDenied v-if="!$helpers.hasPermission(['view_webhook'])" :isDetail="true" />
          <template v-else>
            <v-skeleton-loader class="mt-5" v-if="loading" transition="fade-transition" type="table" height="230" />
            <template v-else>
              <template v-if="webhooksList.length">
                <v-simple-table class="settings-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Estado</th>
                        <th>URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="$router.push({ name: 'AccountSettingsWebhooksRetrieveUpdate', params: { id: d.id } })" v-for="d in webhooksList" :key="d.id">
                        <td>
                          <v-chip v-if="d.status === 'enabled'" color="lightBlue" small text-color="blue-500">Habilitado</v-chip>
                          <v-chip v-else-if="d.status === 'disabled'" color="defaultGrey" small text-color="grey-500">Deshabilitado</v-chip>
                        </td>
                        <td>
                          <span class="grey-500--text font-weight-medium">{{d.url}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider />
                <v-col md="12" class="text-center py-5">
                  <v-btn @click="dialogCreate=true" outlined><v-icon left>mdi-plus</v-icon> Nuevo webhook</v-btn>
                </v-col>
              </template>
              <v-col class="pt-1 pb-2 text-center" v-else>
                <v-empty-state type="webhooks" customClass="mt-8" :isFree="true" title="Aún no tienes webhooks" :height="149" />
                <v-btn v-if="$helpers.hasPermission(['add_webhook'])" @click="dialogCreate=true" outlined><v-icon left>mdi-plus</v-icon>Nuevo webhook</v-btn>
              </v-col>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- dialog new webhook -->
    <v-dialog v-model="dialogCreate" width="800" persistent no-click-animation overlay-color="grey-500">
      <v-card flat>
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Crear un nuevo webhook</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogCreate=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <v-list class="v-list-form-extended pa-0 transparent mb-4">
            <v-list-item class="px-5 pt-0">
              <v-list-item-title>URL de conexión</v-list-item-title>
              <v-list-item-subtitle class="pl-8">
                <v-text-field v-model="$v.newWebhook.url.$model" :error="$v.newWebhook.url.$error" placeholder="Ej: https://webhooks.myproduct.com/axteroid/" outlined required single-line dense hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="px-5 mt-2">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle class="pl-8">
                <v-text-field v-model="$v.newWebhook.description.$model" :error="$v.newWebhook.description.$error" outlined required single-line dense hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list class="v-list-form-extended pa-5 transparent">
            <v-list-item class="py-2">
              <v-list-item-title style="align-self: start">
                <span class="d-block">Eventos</span>
                <!-- <v-btn class="my-1" href="#" text color="blue-500" :ripple="false" small>Aprende más sobre los eventos</v-btn> -->
              </v-list-item-title>
              <v-list-item-subtitle class="pl-8">
                <v-card class="mx-1" outlined flat>
                  <v-toolbar color="transparent" flat height="38">
                    <v-toolbar-title class="pl-5 body-1 grey-700--text">Seleccionar eventos</v-toolbar-title>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="pa-0">
                  <v-col class="pb-0 mt-1">
                    <v-text-field v-model="search" autocomplete="off" background-color="white-0" dense hide-details flat solo placeholder="Buscar..." prepend-inner-icon="mdi-magnify" clearable clear-icon="mdi-close" @click:clear="search = ''" height="16" />
                  </v-col>
                 <v-divider />
                 <v-col class="pt-3 pb-2" v-if="eventsList.length" style="overflow-y: scroll; height:189px">
                    <template v-if="currentEventList.length">
                      <div class="d-flex align-center px-3" v-for="(event, i) in currentEventList" :key="`${i}-item`">
                        <v-checkbox
                          class="custom-checkbox"
                          @click="getSelect(event)"
                          :input-value="newWebhook.events.includes(event) || false"
                          dense
                          color="blue-500"
                          hide-details
                        />
                        <span class="body-2 grey-500--text pt-1">{{event.name}}</span>
                        <!-- <v-list-item class="py-0 my-0" >
                          <v-list-item-title>{{d}}--</v-list-item-title>
                          <v-list-item-action class="ma-0 text-right">
                            <v-btn @click="newWebhook.events.splice(i, 1)" text color="blue-500" :ripple="false" small><v-icon right small>mdi-close</v-icon></v-btn>
                          </v-list-item-action>
                        </v-list-item> -->
                      </div>
                    </template>
                    <v-col class="text-center pt-13" v-else>
                      <v-icon color="grey-300--text" size="48">mdi-magnify-close</v-icon>
                      <span class="d-block body-1 font-weight-bold">No se han encontrado resultados</span>
                    </v-col>
                  </v-col>
                  <v-col class="text-center py-9" v-else>
                    <v-icon class="d-block" color="grey-300--text" size="48">mdi-calendar-remove</v-icon>
                    <span class="d-block body-1 font-weight-bold">No existen eventos</span>
                  </v-col>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-1">
                  <span v-if="eventsList.length" class="mx-2 body-2">{{newWebhook.events.length}} eventos seleccionados</span>
                  <v-spacer />
                  <v-btn class="body-1" @click="newWebhook.events = []" color="blue-500" :ripple="false" text>Limpiar</v-btn>
                  <!-- <v-btn @click="selectAll" :ripple="false" color="blue-500">Seleccionar todos</v-btn> -->
                </v-card-actions>
              </v-card>
            </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogCreate=false" outlined>Cancelar</v-btn>
          <v-btn @click="postWebhook" :loading="$store.state.accounts.createLoader" color="blue-500">Crear webhook</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new webhook -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, url } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import AccessApi from '@/modules/account/views/AccessApi'
import VEmptyState from '@/components/commons/VEmptyState'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    AccessApi,
    VEmptyState,
    PermissionDenied
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    search: '',
    newWebhook: {
      description: '',
      events: [],
      url: ''
    },
    dialogCreate: false,
    loading: false
  }),
  computed: {
    ...mapState({
      eventsList: state => state.accounts.eventsList,
      webhooksList: state => state.accounts.webhooksList
    }),
    currentEventList () {
      if (this.search?.length) {
        return this.eventsList.filter(e => e.name.toLowerCase().includes(this.search.toLowerCase()))
      }
      return this.eventsList
    }
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('accounts/LIST', {
      resource: 'events',
      params: {
        page_size: 100
      }
    })
    await this.$store.dispatch('accounts/LIST', {
      resource: 'webhooks'
    })
    this.loading = false
  },
  methods: {
    getSelect (item = {}) {
      if (!this.newWebhook.events?.find((e) => e.id === item.id)) this.newWebhook.events.push(item)
      else {
        const index = this.newWebhook.events.findIndex(i => i.id === item.id)
        this.newWebhook.events.splice(this.newWebhook.events?.indexOf(index), 1)
        this.newWebhook.events = [...this.newWebhook.events]
      }
    },
    selectAll () {
      this.newWebhook.events = this.eventsList
    },
    postWebhook () {
      this.$v.newWebhook.$touch()
      if (this.$v.newWebhook.$invalid) {
        return false
      }
      this.newWebhook.events = this.newWebhook.events.map(e => e.name)
      this.$store.dispatch('accounts/CREATE', {
        resource: 'webhooks',
        payload: this.newWebhook
      })
      .then((response) => {
        this.$router.push({
          name: 'AccountSettingsWebhooksRetrieveUpdate',
          params: {
            id: response.data.id
          }
        })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    toggleSelectedEvents () {
      this.$nextTick(() => {
        if (this.allEventsSelected) {
          this.newWebhook.events = []
        } else {
          this.newWebhook.events = this.eventsList.slice()
        }
      })
    }
  },
  validations: {
    newWebhook: {
      description: {
        maxLength: maxLength(512)
      },
      events: {
        required
      },
      url: {
        required,
        url
      }
    }
  }
}
</script>